import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { InputNumber } from '@/components/form/field-number/InputNumber';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { FieldText } from '@/components/form/field-text/FieldText';
import { TimesheetAdjustmentAction, TimesheetAdjustmentBulkMutation } from '@/domain/timesheet-adjustment/TimesheetAdjustment.model';
import {
    bulkTimesheetAdjustmentCreationRequest,
    getTranslationKeysForTimesheetAdjustmentAction,
} from '@/domain/timesheet-adjustment/TimesheetAdjustment.service';
import { getHoursMinutesFromMinutes, getMinutesFromHoursMinutes, HoursMinutes } from '@/utils/datetime.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, capitalize, DialogActions, DialogContent, FormControlLabel, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    BulkAdjustmentFormSchema,
    getBulkAdjustmentFormSchema,
    TimesheetAdjustmentMode,
} from '@/page/employee-timesheet/bulk-adjustment-dialog/BulkAddAdjustmentForm.schema';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import { StackedAvatars } from '@/components/stacked-avatar/StackedAvatars';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';

export type EmployeeBalance = {
    employee: EmployeeAvatar;
    balanceInMinutes: number;
};

type BulkAddAdjustmentDialogProps = {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    employeeBalances: EmployeeBalance[];
    date: LocalDate;
    isFixedAmount: boolean;
};

export const BulkAddAdjustmentDialog: FC<BulkAddAdjustmentDialogProps> = props => {
    const { t } = useTranslation();
    const { open, onClose, isFixedAmount, ...restProps } = props;

    return (
        <DialogWrapper
            open={open}
            onClose={() => onClose()}
            header={isFixedAmount ? t('bulk_add_adjustment_dialog.fixed_amount_title') : t('bulk_add_adjustment_dialog.reduce_amount_title')}
        >
            <BulkAdjustmentDialogForm isFixedAmount={isFixedAmount} {...restProps} />
        </DialogWrapper>
    );
};

type BulkAddAdjustmentFormProps = {
    onSave: () => void;
    employeeBalances: EmployeeBalance[];
    date: LocalDate;
    isFixedAmount: boolean;
};

const BulkAdjustmentDialogForm: FC<BulkAddAdjustmentFormProps> = ({ onSave, employeeBalances, date, isFixedAmount }) => {
    const { t } = useTranslation();

    const { control, watch, handleSubmit } = useForm<BulkAdjustmentFormSchema>({
        resolver: yupResolver(getBulkAdjustmentFormSchema()),
        defaultValues: getDefaultValues(isFixedAmount, date),
    });

    const hoursMinutes = watch('hoursMinutes');

    const handleSave = (data: BulkAdjustmentFormSchema) => {
        handleAdjustmentCreate(data);
    };

    const handleAdjustmentCreate = async (data: BulkAdjustmentFormSchema) => {
        const employees = filterEmployeesWithBalance(employeeBalances, isFixedAmount, getMinutesFromHoursMinutes(data.hoursMinutes));
        const mutations = generateMutations(data, employees);

        try {
            await bulkTimesheetAdjustmentCreationRequest(mutations);
            showSnackbar(t('bulk_add_adjustment_dialog.adjustment_created', { count: employees.length }), 'success');
            onSave();
        } catch (error) {
            handleError(error);
        }
    };

    const generateMutations = (data: BulkAdjustmentFormSchema, employeeBalances: EmployeeBalance[]): TimesheetAdjustmentBulkMutation[] => {
        const isFixedAmount = data.mode === TimesheetAdjustmentMode.FIXED_AMOUNT;
        return employeeBalances.map(employeeBalance => {
            const fixedAmount =
                data.actionType === TimesheetAdjustmentAction.REMOVE
                    ? -getMinutesFromHoursMinutes(data.hoursMinutes)
                    : getMinutesFromHoursMinutes(data.hoursMinutes);
            const reduceAmount = -(employeeBalance.balanceInMinutes - getMinutesFromHoursMinutes(data.hoursMinutes));
            return {
                employeeId: employeeBalance.employee.id,
                requestDate: data.effectiveDate,
                comment: data.comment,
                amountInMinutes: isFixedAmount ? fixedAmount : reduceAmount,
            };
        });
    };

    const handleHoursChange = (hours: number, onHoursMinutesChange: (data: HoursMinutes) => void) => {
        const newHoursMinutes = {
            ...hoursMinutes,
            hours,
        };
        onHoursMinutesChange(newHoursMinutes);
    };

    const handleMinutesChange = (minutes: number, onHoursMinutesChange: (data: HoursMinutes) => void) => {
        // extract the supplement hours if user set minutes greater than 60
        const suppHours = Math.trunc(minutes / 60);
        const restMinutes = minutes % 60;
        const newHoursMinutes = {
            hours: hoursMinutes.hours + suppHours,
            minutes: restMinutes,
        };
        onHoursMinutesChange(newHoursMinutes);
    };

    const minutes = getMinutesFromHoursMinutes(hoursMinutes);
    const actionOptions = [TimesheetAdjustmentAction.ADD, TimesheetAdjustmentAction.REMOVE];
    const employees = filterEmployeesWithBalance(employeeBalances, isFixedAmount, minutes).map(employeeBalance => employeeBalance.employee);

    return (
        <>
            <DialogContent>
                <Stack gap={2}>
                    {isFixedAmount && (
                        <FormControlLabel
                            sx={{ width: '100%' }}
                            label={t('bulk_add_adjustment_dialog.action_type')}
                            control={
                                <FieldSelect
                                    name={'actionType'}
                                    control={control}
                                    disableClearable
                                    options={actionOptions}
                                    getOptionLabel={actionType => t(getTranslationKeysForTimesheetAdjustmentAction(actionType))}
                                    fullWidth
                                />
                            }
                        />
                    )}

                    <Stack direction={'row'} gap={1} justifyContent={'space-between'}>
                        <Controller
                            name={'hoursMinutes'}
                            control={control}
                            render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                                <>
                                    <Stack flexGrow={1} width={'100%'}>
                                        <FormControlLabel
                                            label={capitalize(isFixedAmount ? t('domain.unit_type.hours') : t('bulk_add_adjustment_dialog.target_hours'))}
                                            labelPlacement='top'
                                            sx={{ flex: 1 }}
                                            control={
                                                <InputNumber
                                                    {...restField}
                                                    value={value.hours}
                                                    onChange={hours => {
                                                        handleHoursChange(hours ?? 0, onChange);
                                                    }}
                                                    precision={0}
                                                    fullWidth
                                                    error={!!error}
                                                    helperText={error?.message}
                                                />
                                            }
                                        />
                                    </Stack>
                                    <Stack flexGrow={1} width={'100%'}>
                                        <FormControlLabel
                                            label={capitalize(isFixedAmount ? t('domain.unit_type.minutes') : t('bulk_add_adjustment_dialog.target_minutes'))}
                                            labelPlacement='top'
                                            sx={{ flex: 1 }}
                                            control={
                                                <InputNumber
                                                    {...restField}
                                                    value={value.minutes}
                                                    onChange={minutes => {
                                                        handleMinutesChange(minutes ?? 0, onChange);
                                                    }}
                                                    precision={0}
                                                    fullWidth
                                                    error={!!error}
                                                    helperText={error?.message}
                                                />
                                            }
                                        />
                                    </Stack>
                                </>
                            )}
                        />
                    </Stack>
                    {!isFixedAmount && (
                        <Stack direction={'row'} alignItems={'center'} gap={1}>
                            <Typography variant='body2'>{t('bulk_add_adjustment_dialog.employees_with_balance_reduced')}</Typography>
                            <StackedAvatars employeeAvatars={employees} />
                        </Stack>
                    )}

                    <FormControlLabel
                        label={t('bulk_add_adjustment_dialog.effective_date')}
                        control={<FieldLocalDate control={control} name={'effectiveDate'} />}
                    />

                    <FormControlLabel
                        label={t('bulk_add_adjustment_dialog.comment')}
                        control={
                            <FieldText
                                name={'comment'}
                                control={control}
                                fullWidth
                                slotProps={{
                                    input: {
                                        multiline: true,
                                        minRows: 2,
                                    },
                                }}
                                placeholder={t('bulk_add_adjustment_dialog.comment_placeholder')}
                            />
                        }
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                {isFixedAmount && <StackedAvatars employeeAvatars={employees} />}
                <Button disabled={!employees.length} onClick={() => handleSubmit(handleSave, console.error)()} fullWidth={!isFixedAmount}>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </>
    );
};

const getHoursMinutesFromTotalMinutes = (amount: number | undefined) => {
    return amount
        ? getHoursMinutesFromMinutes(Math.abs(amount))
        : {
              hours: 0,
              minutes: 0,
          };
};

const getDefaultValues = (isFixedAmount: boolean, effectiveDate: LocalDate): BulkAdjustmentFormSchema => {
    return {
        mode: isFixedAmount ? TimesheetAdjustmentMode.FIXED_AMOUNT : TimesheetAdjustmentMode.REDUCE_TO_TARGET,
        actionType: TimesheetAdjustmentAction.ADD,
        hoursMinutes: getHoursMinutesFromTotalMinutes(0),
        effectiveDate: effectiveDate,
        comment: '',
    };
};

const filterEmployeesWithBalance = (employeeBalances: EmployeeBalance[], isFixedAmount: boolean, minAmount: number) => {
    if (isFixedAmount) {
        return employeeBalances;
    }
    return employeeBalances.filter(employeeBalance => employeeBalance.balanceInMinutes > minAmount);
};
