import { getUserLocale } from '@/utils/language.util';

/**
 * Null/Undefined safe toLowerCase a string
 * @param str A string
 */
export const toLowerCase = (str: string): string => {
    return str ? str.toLowerCase() : str;
};

/**
 * Check if a string contains another string, case insensitive
 * @param str A string
 * @param searchStr A substring to search
 */
export const containsIgnoreCase = (str: string, searchStr: string): boolean => {
    if (!str || !searchStr) {
        return false;
    }

    return str.toLowerCase().includes(searchStr.toLowerCase());
};

/**
 * Null/Undefined safe substring a string
 * @param str A string
 * @param end Zero-based index number indicating the end of the substring
 */
export const substring = (str: string, end = 0): string => {
    return str ? str.substring(0, end) : str;
};

/**
 * Gets the substring after the last occurrence of a separator.
 * The separator is not returned.
 *
 * @param str the String to get a substring from
 * @param separator the String to search for
 * @return the substring after the last occurrence of the separator, an empty string is returned if undefined or not found
 */
export const substringAfterLast = (str: string | undefined, separator: string | undefined): string => {
    if (!str || !separator) {
        return '';
    }
    const position = str.lastIndexOf(separator);

    if (position === -1) {
        return str;
    }

    if (position === str.length - separator.length) {
        return '';
    }
    return str.substring(position + separator.length);
};

/**
 * Gets the substring before the first occurrence of a separator.
 * The separator is not returned.
 *
 * @param str the String to get a substring from
 * @param separator the String to search for
 * @return the substring before the first occurrence of the separator, an empty string is returned if undefined or not found
 */
export const substringBeforeFirst = (str: string | undefined, separator: string | undefined): string => {
    if (!str || !separator) {
        return '';
    }
    const index = str.indexOf(separator);

    if (index === -1) {
        return str;
    }
    return str.substring(0, index);
};

/**
 * Null/Undefined safe replace a string
 * @param str A string
 * @param searchValue search value, string or regex are supported
 * @param replace replace value, default to '' if not provided
 */
export const replace = (str: string, searchValue: string | RegExp, replace = ''): string => {
    return str ? str.replace(searchValue, replace) : str;
};

export const removeAccents = (str: string): string => {
    // Use Unicode property escapes to match non-ASCII characters
    const pattern = /[^\p{ASCII}]/gu;

    // Replace accented characters using JavaScript's `replace` function
    return str.replace(pattern, function (match) {
        // Decompose the accented character into its base character and diacritic mark
        return match.normalize('NFKD').replace(/[\u0300-\u036F]/g, '');
    });
};

/**
 * transform html content to plain text, removing all html tags
 * @param html
 */
export const htmlToPlainText = (html: string): string => {
    return new DOMParser().parseFromString(html, 'text/html').documentElement.textContent ?? '';
};

export const localeCompareString = (a: string, b: string): number => {
    return a.localeCompare(b, getUserLocale().code);
};

export const deleteWhitespace = (str: string): string => {
    return str.replace(/\s/g, '');
};

export const formatIBAN = (iban: string): string => {
    return iban.replace(/(.{4})/g, '$1 ').trim();
};
