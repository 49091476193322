import { CountrySelect } from '@/components/country-select/CountrySelect';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { FieldText } from '@/components/form/field-text/FieldText';
import { EditableDocumentField } from '@/components/section/SectionFieldComponent/EditableDocumentField/EditableDocumentField';
import { SectionField } from '@/components/section/types';
import { CustomList, CustomListItem } from '@/domain/custom-list/CustomList.model';
import { getLabelTranslation } from '@/utils/language.util';
import { getNull } from '@/utils/object.util';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { FieldNumber } from '@/components/form/field-number/FieldNumber';
import { formatIBAN } from '@/utils/strings.util';

export type EditableSectionFieldComponentProps = {
    field: SectionField;
};

export const EditableSectionFieldComponent: FC<EditableSectionFieldComponentProps> = ({ field }) => {
    if (!field?.formValueName) {
        return;
    }

    const commonProps = {
        formValueName: field.formValueName,
        disabled: field.disabled,
    };

    switch (field?.valueType) {
        case 'CUSTOM_LIST_ITEM':
            return (
                <EditableCustomSingleListItem
                    value={field?.customListItemReferences?.map(item => item.id)?.[0]}
                    customList={field?.customList}
                    {...commonProps}
                />
            );
        case 'CUSTOM_MULTI_LIST_ITEM':
            return (
                <EditableCustomMultiListItem
                    values={field?.customListItemReferences?.map(item => item.id) ?? []}
                    customList={field?.customList}
                    {...commonProps}
                />
            );
        case 'ENUM':
            return <EditableEnumListItem value={field?.stringValue ? field.stringValue : getNull()} enumOptions={field?.enumList} {...commonProps} />;
        case 'DATE':
            return <EditableDateField value={field?.dateValue ? field.dateValue : getNull()} {...commonProps} />;
        case 'NUMBER':
            return <EditableNumberField value={field?.numberValue?.toString()} {...commonProps} />;
        case 'STRING':
        case 'PHONE_NUMBER':
        case 'AVS_NUMBER':
        case 'EMAIL':
            return <EditableTextField value={field?.stringValue ?? ''} {...commonProps} />;
        case 'IBAN_NUMBER':
            return <EditableTextField value={formatIBAN(field?.stringValue ?? '')} {...commonProps} />;
        case 'COUNTRY':
            return <CountrySelect countryValue={field?.countryValue ? field.countryValue : getNull()} {...commonProps} />;
        case 'SECTION_FIELD_DOCUMENT':
            return <EditableDocumentField documentsValue={field?.documents ?? []} {...commonProps} />;
        default:
            return undefined;
    }
};

type EditableDateFieldProps = {
    value: LocalDate | null;
    formValueName: string;
    disabled?: boolean;
};

const EditableDateField: FC<EditableDateFieldProps> = ({ value, formValueName, disabled }) => {
    const { control } = useFormContext<Record<string, EditableDateFieldProps['value']>>();

    return <FieldLocalDate control={control} name={formValueName} disabled={disabled} defaultValue={value} />;
};

type EditableTextFieldProps = {
    value: string | null;
    formValueName: string;
    disabled?: boolean;
};

const EditableTextField: FC<EditableTextFieldProps> = ({ value, formValueName, disabled }) => {
    const { control } = useFormContext<Record<string, EditableTextFieldProps['value']>>();

    return <FieldText name={formValueName} control={control} defaultValue={value ?? ''} fullWidth variant='outlined' disabled={disabled} />;
};

type EditableNumberFieldProps = {
    value: string | undefined;
    formValueName: string;
    disabled?: boolean;
};
const EditableNumberField: FC<EditableNumberFieldProps> = ({ value, formValueName, disabled }) => {
    const { control } = useFormContext<Record<string, EditableNumberFieldProps['value']>>();

    return (
        <FieldNumber
            control={control}
            name={formValueName}
            defaultValue={value ?? ''}
            precision={2}
            inputNumberProps={{
                inputProps: { 'aria-label': formValueName },
            }}
            disabled={disabled}
            fullWidth
        />
    );
};

type EditableCustomListItemProps = {
    customList: CustomList | undefined;
    value: number | undefined;
    formValueName: string;
    disabled?: boolean;
};

const EditableCustomSingleListItem: FC<EditableCustomListItemProps> = ({ customList, value, formValueName, disabled }) => {
    const { control } = useFormContext<Record<string, number>>();

    const options = customList?.items.map(item => item.id) ?? [];

    const getCustomListItem = (option: number): CustomListItem | undefined => {
        return customList?.items.find(item => item.id === option);
    };

    return (
        <FieldSelect
            name={formValueName}
            control={control}
            defaultValue={value}
            fullWidth
            disabled={disabled}
            options={options}
            getOptionLabel={option => getLabelTranslation(getCustomListItem(option)?.label)}
        />
    );
};

type EditableCustomMultiListItemProps = {
    customList: CustomList | undefined;
    values: number[];
    formValueName: string;
    disabled?: boolean;
};
const EditableCustomMultiListItem: FC<EditableCustomMultiListItemProps> = ({ customList, values: defaultValues, formValueName, disabled = false }) => {
    const { control } = useFormContext<Record<string, number[]>>();

    const options = customList?.items.map(item => item.id) ?? [];

    const getCustomListItem = (option: number): CustomListItem | undefined => {
        return customList?.items.find(item => item.id === option);
    };

    return (
        <FieldSelect
            name={formValueName}
            control={control}
            defaultValue={defaultValues ?? []}
            fullWidth
            disabled={disabled}
            options={options}
            getOptionLabel={option => getLabelTranslation(getCustomListItem(option)?.label)}
            multiple
        />
    );
};
type EditableEnumListItemProps = {
    enumOptions: { value: string; label: string }[] | undefined;
    value: string | null;
    formValueName: string;
    disabled?: boolean;
};
const EditableEnumListItem: FC<EditableEnumListItemProps> = ({ enumOptions = [], value: defaultValue, formValueName, disabled }) => {
    const { control } = useFormContext<Record<string, EditableEnumListItemProps['value']>>();
    const options = enumOptions.map(enumOption => enumOption.value);

    return (
        <FieldSelect
            name={formValueName}
            control={control}
            // Empty string is not a valid value for enum fields
            defaultValue={defaultValue ?? getNull()}
            fullWidth
            disabled={disabled}
            options={options}
            getOptionLabel={enumValue => enumOptions.find(enumOption => enumOption.value === enumValue)?.label ?? ''}
        />
    );
};
