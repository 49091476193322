import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { InputNumber } from '@/components/form/field-number/InputNumber';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { FieldText } from '@/components/form/field-text/FieldText';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { LeaveType } from '@/domain/leave-type/LeaveType.model';
import { removeNullsFromLeaveType } from '@/domain/leave-type/LeaveType.service';
import {
    TimesheetAdjustment,
    TimesheetAdjustmentAction,
    TimesheetAdjustmentCreateMutation,
    TimesheetAdjustmentUpdateMutation,
} from '@/domain/timesheet-adjustment/TimesheetAdjustment.model';
import {
    createTimesheetAdjustment,
    getTranslationKeysForTimesheetAdjustmentAction,
    updateTimesheetAdjustment,
} from '@/domain/timesheet-adjustment/TimesheetAdjustment.service';
import {
    RecurringRuleType,
    TimesheetRecurringAdjustment,
    TimesheetRecurringAdjustmentCreateMutation,
    TimesheetRecurringAdjustmentUpdateMutation,
} from '@/domain/timesheet-recurring-adjustment/TimesheetRecurringAdjustment.model';
import {
    createTimesheetRecurringAdjustment,
    updateTimesheetRecurringAdjustment,
} from '@/domain/timesheet-recurring-adjustment/TimesheetRecurringAdjustment.service';
import { useGetLeaveTypes } from '@/hooks/leave-type/LeaveType.hook';
import { useGetTimesheetAdjustment } from '@/hooks/timesheet-adjustment/TimesheetAdjustment.hook';
import { useGetTimesheetRecurringAdjustment } from '@/hooks/timesheet-recurring-adjustment/TimesheetRecurringAdjustment.hook';
import { AdjustmentFormSchema, getAdjustmentFormSchema, RecurringRuleTypeOptions } from '@/page/employee-timesheet/adjustment-dialog/AddAdjustmentForm.schema';
import { handleError } from '@/utils/api.util';
import { formatToLocalDate, getHoursMinutesFromMinutes, getMinutesFromHoursMinutes, getTodayDate, HoursMinutes, toDate } from '@/utils/datetime.util';
import { getLabelTranslation } from '@/utils/language.util';
import { getNull } from '@/utils/object.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, capitalize, DialogActions, DialogContent, FormControlLabel, IconButton, Stack, Typography } from '@mui/material';
import { RepeatIcon } from 'hugeicons-react';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type AddAdjustmentDialogProps = {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    adjustmentId?: number;
    recurringAdjustmentId?: number;
    employeeId: number;
};

export const AddAdjustmentDialog: FC<AddAdjustmentDialogProps> = ({ open, onClose, onSave, adjustmentId, recurringAdjustmentId, employeeId }) => {
    const [isRecurring, setIsRecurring] = useState(!!recurringAdjustmentId);

    const { data: leaveTypes = [], isLoading: isLeaveTypesLoading, isError: isLeaveTypesError, error: leaveTypesError } = useGetLeaveTypes();

    const { data: adjustment, isLoading: isAdjustmentLoading, isError: isAdjustmentError, error: adjustmentError } = useGetTimesheetAdjustment(adjustmentId);

    const {
        data: recurringAdjustment,
        isLoading: isRecurringAdjustmentLoading,
        isError: isRecurringAdjustmentError,
        error: recurringAdjustmentError,
    } = useGetTimesheetRecurringAdjustment(recurringAdjustmentId);

    const filterNonUnlimitedLeaveTypes = (leaveTypes: LeaveType[]): LeaveType[] => {
        return leaveTypes.filter(leaveType => leaveType.allowanceType === 'NOT_UNLIMITED');
    };

    const isLoading = isLeaveTypesLoading || isAdjustmentLoading || isRecurringAdjustmentLoading;
    const isError = isLeaveTypesError || isAdjustmentError || isRecurringAdjustmentError;
    const error = leaveTypesError || adjustmentError || recurringAdjustmentError;

    const toggleRecurrenceSelected = () => {
        setIsRecurring(!isRecurring);
    };

    return (
        <DialogWrapper
            open={open}
            onClose={() => onClose()}
            header={
                <AdjustmentHeader
                    adjustment={adjustment}
                    recurringAdjustment={recurringAdjustment}
                    isRecurring={isRecurring}
                    toggleRecurrenceSelected={toggleRecurrenceSelected}
                />
            }
        >
            <StateHandler isLoading={isLoading} isError={isError} error={error}>
                <AddPaymentAdjustmentDialogForm
                    onSave={onSave}
                    leaveTypes={filterNonUnlimitedLeaveTypes(leaveTypes)}
                    adjustment={adjustment}
                    recurringAdjustment={recurringAdjustment}
                    isRecurring={isRecurring}
                    employeeId={employeeId}
                />
            </StateHandler>
        </DialogWrapper>
    );
};

type AddAdjustmentFormProps = {
    onSave: () => void;
    leaveTypes: LeaveType[];
    adjustment: TimesheetAdjustment | undefined;
    recurringAdjustment: TimesheetRecurringAdjustment | undefined;
    isRecurring: boolean;
    employeeId: number;
};

const AddPaymentAdjustmentDialogForm: FC<AddAdjustmentFormProps> = ({ onSave, leaveTypes, adjustment, recurringAdjustment, isRecurring, employeeId }) => {
    const { t } = useTranslation();

    const { control, watch, handleSubmit, setValue } = useForm<AdjustmentFormSchema>({
        resolver: yupResolver(getAdjustmentFormSchema()),
        defaultValues: getDefaultValues(leaveTypes, adjustment, recurringAdjustment),
    });

    useEffect(() => {
        setValue('isRecurring', isRecurring);
        if (isRecurring && watch('actionType') === 'TRANSFER') {
            // if the adjustment is recurring, we cant have the transfer action
            setValue('actionType', TimesheetAdjustmentAction.ADD);
        }
    }, [isRecurring, setValue, watch]);

    const hoursMinutes = watch('hoursMinutes');
    const actionType = watch('actionType');
    const dontApplyOnLeave = watch('dontApplyOnLeave');

    const handleSave = (data: AdjustmentFormSchema) => {
        if (data.isRecurring && recurringAdjustment?.id) {
            return handleRecurringAdjustmentUpdate(data, recurringAdjustment.id);
        }
        if (data.isRecurring) {
            return handleRecurringAdjustmentCreate(data);
        }
        if (adjustment?.id) {
            return handleAdjustmentUpdate(data, adjustment.id);
        }
        handleAdjustmentCreate(data);
    };

    const handleRecurringAdjustmentCreate = async (data: AdjustmentFormSchema) => {
        if (!data.endDate) {
            // the end date is required in the form
            return;
        }
        const mutation: TimesheetRecurringAdjustmentCreateMutation = {
            employeeId: employeeId,
            startDate: formatToLocalDate(data.startDate),
            endDate: formatToLocalDate(data.endDate),
            amountInMinutes: getMinutesFromHoursMinutes(data.hoursMinutes) * (actionType === TimesheetAdjustmentAction.REMOVE ? -1 : 1),
            comment: data.comment,
            recurringRuleType: data.dontApplyOnLeave ? (data.recurringRuleType ?? RecurringRuleType.ALL_WORKING_DAYS) : RecurringRuleType.ALL_WORKING_DAYS,
            contractProrataPartialDays: data.contractProrataPartialDays ?? false,
        };

        try {
            await createTimesheetRecurringAdjustment(mutation);
            onSave();
        } catch (error) {
            handleError(error);
        }
    };

    const handleRecurringAdjustmentUpdate = async (data: AdjustmentFormSchema, id: number) => {
        if (!data.endDate) {
            // the end date is required in the form
            return;
        }
        const mutation: TimesheetRecurringAdjustmentUpdateMutation = {
            startDate: formatToLocalDate(data.startDate),
            endDate: formatToLocalDate(data.endDate),
            amountInMinutes: getMinutesFromHoursMinutes(data.hoursMinutes) * (actionType === TimesheetAdjustmentAction.REMOVE ? -1 : 1),
            comment: data.comment,
            recurringRuleType: data.dontApplyOnLeave ? (data.recurringRuleType ?? RecurringRuleType.ALL_WORKING_DAYS) : RecurringRuleType.ALL_WORKING_DAYS,
            contractProrataPartialDays: data.contractProrataPartialDays ?? false,
        };

        try {
            await updateTimesheetRecurringAdjustment(mutation, id);
            onSave();
        } catch (error) {
            handleError(error);
        }
    };

    const handleAdjustmentCreate = async (data: AdjustmentFormSchema) => {
        const mutation: TimesheetAdjustmentCreateMutation = {
            employeeId: employeeId,
            requestDate: formatToLocalDate(data.startDate),
            comment: data.comment,
            amountInMinutes:
                data.actionType === TimesheetAdjustmentAction.REMOVE || data.actionType === TimesheetAdjustmentAction.TRANSFER
                    ? -getMinutesFromHoursMinutes(data.hoursMinutes)
                    : getMinutesFromHoursMinutes(data.hoursMinutes),
            leaveTypeId: data.actionType === TimesheetAdjustmentAction.TRANSFER ? data.leaveType?.id : undefined,
        };

        try {
            await createTimesheetAdjustment(mutation);
            onSave();
        } catch (error) {
            handleError(error);
        }
    };

    const handleAdjustmentUpdate = async (data: AdjustmentFormSchema, id: number) => {
        const mutation: TimesheetAdjustmentUpdateMutation = {
            requestDate: formatToLocalDate(data.startDate),
            comment: data.comment,
            amountInMinutes:
                data.actionType === TimesheetAdjustmentAction.REMOVE
                    ? -getMinutesFromHoursMinutes(data.hoursMinutes)
                    : getMinutesFromHoursMinutes(data.hoursMinutes),
        };

        try {
            await updateTimesheetAdjustment(mutation, id);
            onSave();
        } catch (error) {
            handleError(error);
        }
    };

    const handleHoursChange = (hours: number, onHoursMinutesChange: (data: HoursMinutes) => void) => {
        const newHoursMinutes = {
            ...hoursMinutes,
            hours,
        };
        onHoursMinutesChange(newHoursMinutes);
    };

    const handleMinutesChange = (minutes: number, onHoursMinutesChange: (data: HoursMinutes) => void) => {
        // extract the supplement hours if user set minutes greater than 60
        const suppHours = Math.trunc(minutes / 60);
        const restMinutes = minutes % 60;
        const newHoursMinutes = {
            hours: hoursMinutes.hours + suppHours,
            minutes: restMinutes,
        };
        onHoursMinutesChange(newHoursMinutes);
    };

    const getRecurringRuleTypeTranslationKey = (option: RecurringRuleType): string => {
        return t('add_adjustment_dialog.recurring_rule_type.enum', {
            context: option,
        });
    };

    const actionOptions = [TimesheetAdjustmentAction.ADD, TimesheetAdjustmentAction.REMOVE];
    if (!adjustment && !recurringAdjustment && !isRecurring && leaveTypes.length > 0) {
        actionOptions.push(TimesheetAdjustmentAction.TRANSFER);
    }
    const shouldDisplayLeaveType = actionType === TimesheetAdjustmentAction.TRANSFER;
    const contractProrataPartialDays = !!watch('contractProrataPartialDays');

    return (
        <>
            <DialogContent>
                <Stack gap={2}>
                    <FormControlLabel
                        sx={{ width: '100%' }}
                        label={t('add_adjustment_dialog.action_type')}
                        control={
                            <FieldSelect
                                name={'actionType'}
                                control={control}
                                disableClearable
                                options={actionOptions}
                                getOptionLabel={actionType => t(getTranslationKeysForTimesheetAdjustmentAction(actionType))}
                                fullWidth
                            />
                        }
                    />
                    <Stack direction={'row'} gap={1} justifyContent={'space-between'}>
                        <Controller
                            name={'hoursMinutes'}
                            control={control}
                            render={({ field: { value, onChange, ...restField }, fieldState: { error } }) => (
                                <>
                                    <Stack flexGrow={1} width={'100%'}>
                                        <FormControlLabel
                                            label={capitalize(t('domain.unit_type.hours'))}
                                            sx={{ flex: 1 }}
                                            control={
                                                <InputNumber
                                                    {...restField}
                                                    value={value.hours}
                                                    onChange={hours => {
                                                        handleHoursChange(hours ?? 0, onChange);
                                                    }}
                                                    precision={0}
                                                    fullWidth
                                                    error={!!error}
                                                    helperText={error?.message}
                                                />
                                            }
                                        />
                                    </Stack>
                                    <Stack flexGrow={1} width={'100%'}>
                                        <FormControlLabel
                                            label={capitalize(t('domain.unit_type.minutes'))}
                                            sx={{ flex: 1 }}
                                            control={
                                                <InputNumber
                                                    {...restField}
                                                    value={value.minutes}
                                                    onChange={minutes => {
                                                        handleMinutesChange(minutes ?? 0, onChange);
                                                    }}
                                                    precision={0}
                                                    fullWidth
                                                    error={!!error}
                                                    helperText={error?.message}
                                                />
                                            }
                                        />
                                    </Stack>
                                </>
                            )}
                        />
                    </Stack>
                    {shouldDisplayLeaveType && (
                        <FormControlLabel
                            label={t('add_adjustment_dialog.transfer_to')}
                            control={
                                <FieldSelect
                                    name={`leaveType`}
                                    control={control}
                                    fullWidth
                                    options={leaveTypes}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={leaveTypeHistory => getLabelTranslation(leaveTypeHistory.name)}
                                    getOptionKey={leaveTypeHistory => leaveTypeHistory.id}
                                />
                            }
                        />
                    )}
                    {isRecurring && <Typography variant='body1bold'>{t('add_adjustment_dialog.recurrence')}</Typography>}
                    <Stack direction={isRecurring ? 'row' : 'column'} gap={1} justifyContent={'space-between'}>
                        <FormControlLabel
                            label={t(isRecurring ? 'add_adjustment_dialog.start_date' : 'add_adjustment_dialog.effective_date')}
                            control={<FieldLocalDate control={control} name={'startDate'} />}
                        />
                        {isRecurring && (
                            <FormControlLabel label={t('add_adjustment_dialog.end_date')} control={<FieldLocalDate control={control} name={'endDate'} />} />
                        )}
                    </Stack>

                    {isRecurring && (
                        <Stack gap={2}>
                            <FormControlLabel
                                label={t('add_adjustment_dialog.contract_prorata_adjustment', { context: contractProrataPartialDays ? 'on' : 'off' })}
                                labelPlacement='end'
                                sx={{ mr: 0 }}
                                control={<FieldSwitch control={control} name='contractProrataPartialDays' />}
                            />
                            <FormControlLabel
                                label={t('add_adjustment_dialog.dont_apply_on_leaves')}
                                labelPlacement='end'
                                sx={{ mr: 0 }}
                                control={<FieldSwitch control={control} name='dontApplyOnLeave' />}
                            />
                            {dontApplyOnLeave && (
                                <FormControlLabel
                                    sx={{ width: '100%' }}
                                    label={t('add_adjustment_dialog.excluded_leave_type')}
                                    control={
                                        <FieldSelect
                                            control={control}
                                            name='recurringRuleType'
                                            options={Object.values(RecurringRuleTypeOptions)}
                                            getOptionLabel={recurringRuleType => t(getRecurringRuleTypeTranslationKey(recurringRuleType))}
                                            disableClearable
                                            fullWidth
                                        />
                                    }
                                />
                            )}
                        </Stack>
                    )}

                    <FormControlLabel
                        label={t('add_adjustment_dialog.comment')}
                        control={
                            <FieldText
                                name={'comment'}
                                control={control}
                                fullWidth
                                slotProps={{
                                    input: {
                                        multiline: true,
                                        minRows: 2,
                                    },
                                }}
                                placeholder={t('add_adjustment_dialog.comment_placeholder')}
                            />
                        }
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleSubmit(handleSave, console.error)()} fullWidth>
                    {t('general.save')}
                </Button>
            </DialogActions>
        </>
    );
};

type AdjustmentHeaderProps = {
    adjustment?: TimesheetAdjustment;
    recurringAdjustment?: TimesheetRecurringAdjustment;
    isRecurring: boolean;
    toggleRecurrenceSelected: () => void;
};
const AdjustmentHeader: FC<AdjustmentHeaderProps> = ({ adjustment, recurringAdjustment, isRecurring, toggleRecurrenceSelected }) => {
    const { t } = useTranslation();

    const shouldDisplayRecurrentButton = !adjustment && !recurringAdjustment;

    return (
        <Stack direction='row' gap={1} alignItems='center' justifyContent={'space-between'} flexGrow={1}>
            <Typography variant='h1'>{t('add_adjustment_dialog.title')}</Typography>

            {shouldDisplayRecurrentButton && (
                <IconButton
                    size='small'
                    aria-label={'recurring'}
                    sx={{ color: isRecurring ? 'primary.main' : undefined }}
                    onClick={() => toggleRecurrenceSelected()}
                >
                    <RepeatIcon />
                </IconButton>
            )}
        </Stack>
    );
};

const getHoursMinutesFromTotalMinutes = (amount: number | undefined) => {
    return amount
        ? getHoursMinutesFromMinutes(Math.abs(amount))
        : {
              hours: 0,
              minutes: 0,
          };
};

const getDefaultValues = (
    leaveTypes: LeaveType[],
    adjustment?: TimesheetAdjustment,
    recurringAdjustment?: TimesheetRecurringAdjustment,
): AdjustmentFormSchema => {
    if (recurringAdjustment || adjustment) {
        const amountInMinutes = recurringAdjustment?.amountInMinutes ?? adjustment?.amountInMinutes;
        const startDate = recurringAdjustment?.startDate ?? adjustment?.requestDate;
        const dontApplyOnLeave = recurringAdjustment?.recurringRuleType !== 'ALL_WORKING_DAYS';
        return {
            isRecurring: !!recurringAdjustment,
            leaveType: getNull(),
            actionType: amountInMinutes && amountInMinutes < 0 ? TimesheetAdjustmentAction.REMOVE : TimesheetAdjustmentAction.ADD,
            hoursMinutes: getHoursMinutesFromTotalMinutes(recurringAdjustment?.amountInMinutes ?? adjustment?.amountInMinutes),
            startDate: startDate ? toDate(startDate) : getTodayDate(),
            endDate: recurringAdjustment?.endDate ? toDate(recurringAdjustment.endDate) : undefined,
            comment: recurringAdjustment?.comment ?? adjustment?.comment ?? '',
            contractProrataPartialDays: recurringAdjustment?.contractProrataPartialDays,
            dontApplyOnLeave: dontApplyOnLeave,
            recurringRuleType: dontApplyOnLeave ? recurringAdjustment?.recurringRuleType : undefined,
        };
    }
    return getNonRecurringDefaultValues(leaveTypes);
};

const getNonRecurringDefaultValues = (leaveTypes: LeaveType[]) => {
    return {
        isRecurring: false,
        leaveType: leaveTypes[0] ? removeNullsFromLeaveType(leaveTypes[0]) : getNull(),
        actionType: TimesheetAdjustmentAction.ADD,
        hoursMinutes: getHoursMinutesFromTotalMinutes(0),
        startDate: getTodayDate(),
        endDate: getTodayDate(),
        comment: '',
        contractProrataPartialDays: false,
        dontApplyOnLeave: false,
        recurringRuleType: undefined,
    };
};
