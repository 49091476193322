import { SplitButtonGroup } from '@/components/button-group/SplitButtonGroup';
import { Employee } from '@/domain/employee/Employee.model';
import { LeaveTypeHistory } from '@/domain/leave-type-history/LeaveTypeHistory.model';
import {
    canLockEmployeePayrollLock,
    canManagePendingTimesheets,
    canManageTimesheetAdjustments,
    canManageTimesheetPayments,
} from '@/domain/permission/Permission.service';
import { MonthlyTimesheetReport, TimesheetAction, TimesheetCycle } from '@/domain/timesheet/Timesheet.model';
import { AddAdjustmentDialog } from '@/page/employee-timesheet/adjustment-dialog/AddAdjustmentDialog';
import { AddPaymentDialog } from '@/page/employee-timesheet/payment-dialog/AddPaymentDialog';
import { TotalOvertimeFields } from '@/page/employee-timesheet/total-overtime-card/TotalOvertimeFields';
import { LockTimesheetDialog } from '@/page/payroll/lock-timesheet-dialog/LockTimesheetDialog';
import { TimesheetDialog } from '@/page/timesheet/timesheet-dialog/TimesheetDialog';
import { useCurrentPolicies, useCurrentRealm } from '@/stores/store';
import { formatDate, getCurrentLocalDate, MONTHS } from '@/utils/datetime.util';
import { MenuItem, Paper, Select, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Add01Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type TimesheetHeaderProps = {
    onUpdateTimesheetRequest: (timesheetCycle: TimesheetCycle) => void;
    selectedTimesheetCycle: TimesheetCycle;
    timesheetCycles: TimesheetCycle[];
    timesheetsMonthly: MonthlyTimesheetReport | undefined;
    currentTimesheetCycle: TimesheetCycle;
    employee: Employee;
    onSuccess: () => void;
    leaveTypeHistoriesToday: LeaveTypeHistory[];
    leaveTypeHistoriesForecasted: LeaveTypeHistory[];
};

export const TimesheetHeader: FC<TimesheetHeaderProps> = ({
    onUpdateTimesheetRequest,
    selectedTimesheetCycle,
    employee,
    onSuccess,
    timesheetCycles,
    timesheetsMonthly,
    currentTimesheetCycle,
    leaveTypeHistoriesToday,
    leaveTypeHistoriesForecasted,
}) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const policies = useCurrentPolicies();
    const realm = useCurrentRealm();

    const [mode, setMode] = useState<TimesheetAction>(TimesheetAction.CREATE);
    const [openTimesheetsDialog, setOpenTimesheetsDialog] = useState<boolean>(false);
    const [lockTimesheetDialogOpen, setLockTimesheetDialogOpen] = useState<boolean>(false);
    const [paymentDialogOpen, setPaymentDialogOpen] = useState<boolean>(false);
    const [adjustmentDialogOpen, setAdjustmentDialogOpen] = useState<boolean>(false);

    const handleAdjustmentDialogSave = () => {
        setAdjustmentDialogOpen(false);
        onSuccess();
    };

    const handlePaymentDialogSave = () => {
        setPaymentDialogOpen(false);
        onSuccess();
    };

    const onEmployeePayrollLockSave = () => {
        setLockTimesheetDialogOpen(false);
        onSuccess();
    };

    const shouldDisplayLockButton =
        !canManagePendingTimesheets(policies, employee?.id) && canLockEmployeePayrollLock(realm?.realmFeatures ?? [], policies, employee?.id);

    const lockTimesheetDialogButtonKey = 'lockTimesheetDialogButton';
    const buildMenuItems = () => {
        const menuItemsProps = [];

        if (canManagePendingTimesheets(policies, employee?.id)) {
            menuItemsProps.push({
                title: t('timesheets.timesheet'),
                onClick: () => {
                    setMode(TimesheetAction.CREATE);
                    setOpenTimesheetsDialog(true);
                },
            });
            if (canManageTimesheetPayments(policies, employee?.id)) {
                menuItemsProps.push({
                    title: t('timesheets_tab_page.add_payment'),
                    onClick: () => {
                        setPaymentDialogOpen(true);
                    },
                    key: 'addPaymentsDialogButton',
                });
            }

            if (canManageTimesheetAdjustments(policies, employee?.id)) {
                menuItemsProps.push({
                    title: t('timesheets_tab_page.add_adjustment'),
                    onClick: () => {
                        setAdjustmentDialogOpen(true);
                    },
                    key: 'addAdjustmentsDialogButton',
                });
            }

            if (canLockEmployeePayrollLock(realm?.realmFeatures ?? [], policies, employee?.id)) {
                menuItemsProps.push({
                    title: t('timesheets_tab_page.lock_timesheet'),
                    onClick: () => {
                        setLockTimesheetDialogOpen(true);
                    },
                    key: 'lockTimesheetDialogButton',
                });
            }
        }
        if (shouldDisplayLockButton) {
            menuItemsProps.push({
                title: t('timesheets_tab_page.lock_timesheet'),
                onClick: () => {
                    setLockTimesheetDialogOpen(true);
                },
                key: lockTimesheetDialogButtonKey,
            });
        }

        return menuItemsProps;
    };

    const menuItemsProps = buildMenuItems();

    const getTimesheetCycleByYear = (year: string) => {
        return timesheetCycles.find(cycle => cycle.year.toString() === year) ?? selectedTimesheetCycle;
    };

    const isOnlyLockTimesheetButton = menuItemsProps.length === 1 && menuItemsProps[0].key === lockTimesheetDialogButtonKey;

    return (
        <Stack pb={1}>
            <Stack
                direction={isMobile ? 'column' : 'row'}
                component={Paper}
                justifyContent={'space-between'}
                alignItems={isMobile ? 'flex-start' : 'center'}
                p={isMobile ? 2 : 1}
                gap={isMobile ? 1 : 0}
            >
                {timesheetsMonthly && (
                    <TotalOvertimeFields
                        timesheetsMonthly={timesheetsMonthly}
                        currentTimesheetCycle={currentTimesheetCycle}
                        selectedTimesheetCycle={selectedTimesheetCycle}
                        employeeId={employee.id}
                        leaveTypeHistoriesToday={leaveTypeHistoriesToday ?? []}
                        leaveTypeHistoriesForecasted={leaveTypeHistoriesForecasted}
                    />
                )}
                <Stack direction={'row'} alignItems='center' spacing={1} justifyContent='flex-end' alignSelf='flex-start'>
                    {!isMobile && (
                        <Select
                            value={selectedTimesheetCycle.year.toString()}
                            onChange={event => onUpdateTimesheetRequest(getTimesheetCycleByYear(event.target.value))}
                        >
                            {timesheetCycles.map(cycle => (
                                <MenuItem key={cycle.year.toString()} value={cycle.year.toString()}>
                                    {formatDate(cycle.cycleStartDate, cycle.startMonth === MONTHS.JANUARY ? 'yyyy' : 'MMM yyyy')}
                                </MenuItem>
                            ))}
                        </Select>
                    )}

                    <SplitButtonGroup
                        buttonProps={{
                            variant: 'contained',
                            startIcon: !isOnlyLockTimesheetButton ? <Add01Icon size={20} /> : undefined,
                            size: 'small',
                        }}
                        menuItemsProps={menuItemsProps}
                        ariaLabel={'Publish or Export as PDF'}
                    />
                </Stack>
            </Stack>
            {openTimesheetsDialog && (
                <TimesheetDialog
                    mode={mode}
                    open={openTimesheetsDialog}
                    defaultReferenceDate={getCurrentLocalDate()}
                    employee={employee}
                    onClose={() => {
                        setOpenTimesheetsDialog(false);
                    }}
                    onSave={() => {
                        onUpdateTimesheetRequest(selectedTimesheetCycle);
                        setOpenTimesheetsDialog(false);
                    }}
                />
            )}
            {lockTimesheetDialogOpen && (
                <LockTimesheetDialog
                    onClose={() => setLockTimesheetDialogOpen(false)}
                    onSave={() => onEmployeePayrollLockSave()}
                    employeeIds={[employee?.id]}
                />
            )}
            {adjustmentDialogOpen && (
                <AddAdjustmentDialog
                    open={adjustmentDialogOpen}
                    onClose={() => {
                        setAdjustmentDialogOpen(false);
                    }}
                    onSave={handleAdjustmentDialogSave}
                    employeeId={employee.id}
                />
            )}
            {paymentDialogOpen && (
                <AddPaymentDialog
                    open={paymentDialogOpen}
                    onClose={() => {
                        setPaymentDialogOpen(false);
                    }}
                    onSave={handlePaymentDialogSave}
                    employeeId={employee.id}
                />
            )}
        </Stack>
    );
};
