import * as yup from 'yup';
import { hoursMinutesSchema } from '@/utils/datetime.schema';
import { TimesheetAdjustmentAction } from '@/domain/timesheet-adjustment/TimesheetAdjustment.model';
import i18next from 'i18next';
import { getMinutesFromHoursMinutes, HoursMinutes } from '@/utils/datetime.util';

export enum TimesheetAdjustmentMode {
    FIXED_AMOUNT = 'FIXED_AMOUNT',
    REDUCE_TO_TARGET = 'REDUCE_TO_TARGET',
}

export const getBulkAdjustmentFormSchema = () => {
    return yup
        .object()
        .shape({
            mode: yup.string().oneOf(Object.values(TimesheetAdjustmentMode)),
            actionType: yup
                .string()
                .oneOf(Object.values(TimesheetAdjustmentAction).filter(value => value !== TimesheetAdjustmentAction.TRANSFER))
                .when('mode', {
                    is: (mode: TimesheetAdjustmentMode) => mode === TimesheetAdjustmentMode.REDUCE_TO_TARGET,
                    then: schema => schema.nullable(),
                    otherwise: schema => schema.required(),
                }),
            hoursMinutes: hoursMinutesSchema.required().when('mode', {
                is: (mode: TimesheetAdjustmentMode) => mode === TimesheetAdjustmentMode.REDUCE_TO_TARGET,
                then: schema => schema,
                otherwise: schema =>
                    schema.test({
                        message: i18next.t('general.validations.required'),
                        test: (value: HoursMinutes) => getMinutesFromHoursMinutes(value) > 0,
                    }),
            }),
            comment: yup.string().default(''),
            effectiveDate: yup.string<LocalDate>().required(),
        })
        .required();
};

export type BulkAdjustmentFormSchema = yup.InferType<ReturnType<typeof getBulkAdjustmentFormSchema>>;
